import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0004 from '../../../components/molecules/columnDetailInner0004'
import UsefulFunctionLayout002 from '../../../components/molecules/usefulFunctionLayout002'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0004 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        '働き方改革関連法による改正で何が変わったのか？残業時間の規制などを解説',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="働き方改革とは？勤怠管理において重要な労働時間に関する内容を紹介"
        description="一度は耳にしたことがある働き方改革。実際に行われた法改正のうち、労働時間に関する内容にスポットを当て、法改正に伴い企業が行うべき対応策を含めて順に解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0004/"
        ogType="article"
        ogTitle="働き方改革とは？勤怠管理において重要な労働時間に関する内容を紹介"
        ogDescription="一度は耳にしたことがある働き方改革。実際に行われた法改正のうち、労働時間に関する内容にスポットを当て、法改正に伴い企業が行うべき対応策を含めて順に解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0004.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0004 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout002 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0004

export const pageQuery = graphql`
  query C0004 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
