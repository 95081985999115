import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0004 = () => (
  <Wrapper>
    <ColumnH1 label="働き方改革とは？勤怠管理において重要な労働時間に関する内容を紹介" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0003.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        <span>「働き方改革」</span>
        という言葉を一度は耳にしたことがあるのではないでしょうか。
        <br />
        ワーク・ライフ・バランスや多様で柔軟性のある働き方を実現するため、国を挙げて打ち出された施策である働き方改革は、正式名を
        <span>
          「働き方改革関連法（働き方改革を推進するための関係法律の整備に関する法律）」
        </span>
        といい、雇用関連のさまざまな法律の改正が行われています。
        <br />
        ここでは、働き方改革関連法によって実際に行われた法改正のうち、労働時間に関する内容にスポットを当てて、具体的に紹介していきます。法改正に伴い企業がどのような対応策を取れば良いかという内容とあわせて順に解説していきますので、参考にしてみて下さい。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">「働き方改革関連法」とは何か</a>
          </li>
          <li>
            <a href="#toc2">働き方改革関連法により行われた法改正の内容とは</a>
            <ul>
              <li>
                <a href="#toc2-1">①残業時間の上限規制</a>
              </li>
              <li>
                <a href="#toc2-2">②有給休暇の時季指定</a>
              </li>
              <li>
                <a href="#toc2-3">③月60時間を超える残業の割増賃金率引き上げ</a>
              </li>
              <li>
                <a href="#toc2-4">④労働時間の客観的な把握義務</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc3">まとめ</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>「働き方改革関連法」とは何か</h1>
      </div>
      <p>
        働き方改革関連法とは、労働者の抱える育児や介護、プライベートとの両立など多岐にわたる事情にマッチした働き方を選ぶことのできる社会を実現するために推進している「働き方改革」を実現するため、既存の法律を改正した内容をいいます。
        <br />
        「働き方改革関連法」という名前でありながら、一つの独立した法律ではないことを覚えておきましょう。
        <br />
        <br />
        働き方改革を実現するために改正された既存の法律は、次の8種類です。
        <br />
        ・労働基準法
        <br />
        ・労働基準法
        <br />
        ・労働安全衛生法
        <br />
        ・じん肺法
        <br />
        ・パートタイム労働法
        <br />
        ・労働者派遣法
        <br />
        ・労働契約法
        <br />
        ・雇用対策法
        <br />
        <br />
        上記は、いずれも企業が把握する必要のある、雇用に関する重要な法律になります。今回のように、一度に複数の法律で大がかりな改正が行われるという事はあまりなく、国がいかに働き方改革の必要性を重視しているかが見てとれるでしょう。
        <br />
        その要因としては、進行し続ける少子高齢化に伴う労働人口の減少や、依然として社会問題化している長時間労働、異なる雇用形態における待遇の格差、有給休暇の取得率低迷、育児や介護との両立に関する懸念点など、我が国をとりまく雇用にまつわる深刻な問題が挙げられます。
        <br />
        これらの問題に対応するため定められた法律が<span>「働き方関連法」</span>
        であり、各企業ではこの内容に応じた対応を取ることが急務とされています。
      </p>
      <div id="toc2">
        <h1>働き方改革関連法により行われた法改正の内容とは</h1>
      </div>
      <p>
        ここからは、働き方改革関連法の成立によりどのような法改正が行われたのか、具体的な内容について見ていきましょう。
      </p>
      <div id="toc2-1">
        <h2>①残業時間の上限規制</h2>
      </div>
      <p>
        残業時間の抑制は、長時間労働に関する問題を解決するための、最も直接的な施策です。これまでは、残業時間の上限に関する規制はなく、際限のない長時間労働に関しては行政指導が入るなどの対応が実施されているのみの状態でした。
        <br />
        そこで、今回の法改正では、
        <span>
          残業時間の上限時間が原則「月あたり45時間、年間あたり360時間」
        </span>
        という具体的な数値が定められました。月あたり45時間とは、単純に按分をすると一日あたりの残業時間の上限が
        <span>「2時間」</span>
        と算出されます。慢性的な残業が繰り返し行われている企業は、この数値を改めて念頭に置き、早急に対策を取る必要があるでしょう。
        <br />
        <br />
        また、臨時性を要する特別な事情がある場合で、労使間で合意がなされている場合でも、
        <span>
          「年間あたり720時間以内、複数の月平均で80時間以内、月あたり100時間未満」
        </span>
        という上限が定められました。どんな事情があったとしても、前述の数値以上の残業はさせてはならない、という明確な規定がなされています。
        <br />
        <br />
        なお、この規定に違反をして社員に長時間労働をさせた場合は、
        <span>6ヶ月以下の懲役または30万円以下の罰金刑</span>
        に処せられる可能性があります。
      </p>
      <div id="toc2-2">
        <h2>②有給休暇の時季指定</h2>
      </div>
      <p>
        有給休暇は、そもそも社員が心身をリフレッシュさせるために取得することのできる権利として定められていますが、業務量の兼ね合いや周囲への配慮から、取得をためらうケースが多々みられ、これに応じて有給休暇御取得率は低調な状況が続いています。
        <br />
        これに対応するため、有給休暇のうち
        <span>
          年間で5日までの分については、会社側が時季を指定して取得させなければならない
        </span>
        よう法改正が行われました。
        <br />
        対象となるのは、有給休暇が年間で10日以上与えられる社員であり、付与日から1年以内に5日分を取得させることが義務づけられます。ただし、すでに5日以上の有給休暇を取得済みの社員に対しては、時季指定による付与を行う必要はありません。
        <br />
        <br />
        企業側としては、まずは各社員の有給休暇取得状況を洗い出した上で、業務に支障をきたすことなく確実に付与できる方法を検討する必要があるでしょう。なお、企業に対してはさらに
        <span>社員ごとの有給休暇管理簿の作成・保存が義務</span>
        づけられましたので、管理簿の管理体制もあわせて整える必要があります。
      </p>
      <Introduction001 />
      <div id="toc2-3">
        <h2>③月60時間を超える残業の割増賃金率引き上げ</h2>
      </div>
      <p>
        原則として、1日に8時間、1週間で40時間を超える労働をさせた場合には、
        <span>25％の割増賃金率を加算した残業代を支払う</span>
        必要があります。そして、1ヶ月あたり60時間を超える残業については、大企業のみ割増賃金率が50％へ引き上げられるという施策が取られていました。
        <br />
        <br />
        この施策には、長時間の残業に対しての労働者への労いの対応であると同時に、割増賃金率の引き上げにより企業が長時間残業を抑制させる流れを促すねらいがあります。この流れをより促進させるため、今回の法改正では、割増賃金率の引き上げが「中小企業」に対しても行われることになりました。したがって、
        <span>
          すべての企業では、1ヶ月あたり60時間を超える残業が行われた場合は、一律で割増賃金率が50％
        </span>
        になります。
        <br />
        <br />
        なお、実際に中小企業を対象とした引き上げの義務化が行われるのは2023年3月末となっておりますが、今のうちから社内体制の整備や準備をしておいた方が良いでしょう。
      </p>
      <div id="toc2-4">
        <h2>④労働時間の客観的な把握義務</h2>
      </div>
      <p>
        残業代を正しい金額で支払うための対応として、これまで企業には労働者の労働時間を客観的に把握することが規定されていましたが、管理監督者の立場にある者や労働時間が社員個人の判断にゆだねられる裁量労働制の対象者などは、客観的把握の対象外とされていました。
        <br />
        しかし、長時間労働の把握がされていない社員が存在することは健康管理上で問題があるといえることから法改正が行われ、今後は
        <span>すべての社員の労働状況を客観的な方法で管理する</span>
        ことが義務づけられました。
        <br />
        この施策により、長時間労働者に対する面接指導など、社員の安全に配慮した対応が取りやすくなることがねらいとされています。
      </p>
      <div id="toc3">
        <h1 className="read">まとめ</h1>
      </div>
      <p>
        働き方改革関連法は、大企業だけではなく、中小企業も対象となる法改正が多々行われています。大企業と比べ人員数に限りのある中小企業では、日々の業務に追われ社内体制の見直しや各社員の就労状況が目に見えにくい特徴があります。
        <br />
        <br />
        法改正の内容を正しく把握し、対応しなければならないこの機会に一度会社の社内体制を根本から見直してみてはいかがでしょうか。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0004
